module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Andrea Salvatori - Sonic0","description":"The personal site of a very cute person called Andrea Salvatori ᕕ( ᐛ )ᕗ","start_url":"/","background_color":"#fff","theme_color":"#A449FF","display":"minimal-ui","icon":"src/images/avatar.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"aff79a5373597d0f78dc5a5e2e6d2d9b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
